import React, {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import { ethers } from 'ethers';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import apiClient from "../../request/http-common";
import { useDispatch } from 'react-redux';
import { setUser } from '../Auth/userSlice';
import { setAuthStatus, setToken } from '../../request/Auth/authSlice';

const ConnectWallet = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const [connected, setConnected] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [error, setErros] = useState('');

    const { isLoading: isloggingIn, mutate: postLogin } = useMutation(

        async () => {
          return await apiClient.post(`/api/login`, {
            address: walletAddress,
            password: 'trustutils'

          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('auth-token', res.data.access_token);
            dispatch(setUser(res.data.user));
            dispatch(setAuthStatus('authenticated'));
            dispatch(setToken(res.data.access_token));
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            setTimeout(() => {
                window.location.href='/dashboard';
            }, 1000);
            
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.message) 
            const event2 = new Event('processed');
            window.dispatchEvent(event2);      
          },
        }
      );

    const { isLoading: isSendingRequest, mutate: postRegister } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/register`, {
            address: walletAddress,
            password: 'trustutils'
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('auth-token', res.data.access_token);
            dispatch(setUser(res.data.user));
            dispatch(setAuthStatus('authenticated'));
            dispatch(setToken(res.data.access_token));
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
           setTimeout(() => {
            window.location.href='/dashboard';
           }, 100);

          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );

    // Function to connect/disconnect the wallet
    async function connectWallet() {

        if (!isAuth) {
          
            if (!connected) {
                // Connect the wallet using ethers.js
                const provider = new ethers.BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();
                console.log(signer+'is the signer');
                localStorage.setItem('signer', JSON.stringify(signer));
                const _walletAddress = await signer.getAddress();
                setConnected(true);
                setWalletAddress(_walletAddress);
                setTimeout(() => {
                    postRegister();
                }, 2000);
                } else {
                    postRegister();
                // Disconnect the wallet
                // window.ethereum.selectedAddress = null;
                
                }
        }else {
            // if user is logged in
            if (!connected) {
                // Connect the wallet using ethers.js
                const provider = new ethers.BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();
                console.log(signer+'is the signer');
                localStorage.setItem('signer', JSON.stringify(signer));
                const _walletAddress = await signer.getAddress();
                setConnected(true);
                setWalletAddress(_walletAddress);
                setTimeout(() => {
                    postLogin()
                }, 2000);
                } else {
                    postLogin();
                // Disconnect the wallet
                // window.ethereum.selectedAddress = null;
                
                }
        }
       
    }

  
  useEffect(() => {
    connectWallet();

  }, []);

  return (
    <div>
        {isSendingRequest? 'wait....!' : ''}
      {/* <Button onClick={connectWallet} variant="contained" size='large' color="success">
      {connected ? walletAddress: "Connect Wallet"}
      </Button>
      <Typography>
        {walletAddress}
      </Typography> */}
    </div>
  )
}

export default ConnectWallet
